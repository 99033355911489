import { HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RefreshTokenResponse } from '@fca-app/api/fca/auth/interfaces/response/refresh-token.response';
import { ClearAuthDataAction, SaveAuthDataAction } from '@fca-app/store/actions';
import { FcaStoreService } from '@fca-app/store/store.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private readonly authService: AuthService,
        private readonly storeService: FcaStoreService,
        private readonly permissionsService: NgxPermissionsService,
        private router: Router
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): any {
        return this.storeService.getAuthData().pipe(
            tap(data => {
                if (data?.permissions) {
                    this.permissionsService.loadPermissions(data.permissions);
                }
            }),
            map(tokens => {
                return request.clone({
                    setHeaders: { Authorization: request.headers.get('authorization') || `Bearer ${tokens?.jwt}` },
                });
            }),
            switchMap(req => {
                return this.isRefreshRequest(req)
                    ? next.handle(req)
                    : next.handle(req).pipe(
                          catchError(err => {
                              if ([HttpStatusCode.Forbidden, HttpStatusCode.Unauthorized].includes(err.status)) {
                                  return this.storeService.getAuthData().pipe(
                                      take(1),
                                      tap(authData => {
                                          if (!authData?.refreshToken) {
                                              throwError(err);
                                          }
                                      }),
                                      switchMap(authData => {
                                          return this.authService.getNewTokens().pipe(
                                              switchMap((tokens: RefreshTokenResponse) => {
                                                  this.storeService.dispatch(
                                                      new SaveAuthDataAction({
                                                          data: {
                                                              ...tokens,
                                                              email: authData?.email || '',
                                                              permissions: authData?.permissions || [],
                                                          },
                                                      })
                                                  );
                                                  return next.handle(
                                                      request.clone({
                                                          setHeaders: {
                                                              Authorization: `Bearer ${tokens?.refreshToken}`,
                                                          },
                                                      })
                                                  );
                                              })
                                          );
                                      }),
                                      catchError(e => {
                                          this.storeService.dispatch(new ClearAuthDataAction());
                                          this.router.navigate(['/login']);
                                          return throwError(e);
                                      })
                                  );
                              }
                              return throwError(err);
                          })
                      );
            })
        );
    }

    private isRefreshRequest(request: HttpRequest<any>): boolean {
        return request.url.indexOf('auth/refresh') >= 0;
    }
}
